var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "450"
    },
    model: {
      value: _vm.deleteDialog,
      callback: function callback($$v) {
        _vm.deleteDialog = $$v;
      },
      expression: "deleteDialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline primary white--text"
  }, [_vm._v(" " + _vm._s(_vm.$t("confirmation")) + " ")]), _c('v-card-text', {
    staticClass: "pt-2"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("page_admin_users_edit_confirmation_message")) + " ")])], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "disabled": _vm.loading,
      "color": "red",
      "text": ""
    },
    on: {
      "click": function click($event) {
        [_vm.deleteDialog = false, _vm.itemId = null];
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]), _c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.confirmDelete();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")])], 1)], 1)], 1), _c('v-card-title', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "label",
    attrs: {
      "text": "",
      "color": "transparent"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "secondary",
      "left": "",
      "size": "20"
    }
  }, [_vm._v("$addAdminUser")]), _c('div', {
    staticClass: "font-weight-regular secondary--text text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.$t("page_admin_users_edit_title")) + " ")])], 1)], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular",
    attrs: {
      "small": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'AdminUsers'
        });
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "secondary",
      "left": ""
    }
  }, [_vm._v("$goBack")]), _vm._v(" " + _vm._s(_vm.$t("go_back")) + " ")], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-container', [_c('validation-observer', {
    ref: "observer"
  }, [_c('form', {
    attrs: {
      "autocomplete": "off"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "3"
    }
  }, [_c('span', {
    staticClass: "label-text"
  }, [_vm._v(_vm._s(_vm.$t("id")))])]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v(" " + _vm._s(_vm.singleUser.id) + " ")])]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "3"
    }
  }, [_c('span', {
    staticClass: "label-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("last_login_at")) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v(" " + _vm._s(_vm.singleUser.last_login) + " ")])])], 1)], 1), _vm._l(_vm.fields, function (field) {
    return _c('v-col', {
      key: field.name,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "text-right label-text",
      attrs: {
        "cols": "4",
        "md": "2"
      }
    }, [_vm._v(" " + _vm._s(field.label) + " ")]), field.type === 'label' ? _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      "class": field.color ? field.color + '--text' : ''
    }, [_vm._v("U10234")])]) : field.type === 'text' || field.type === 'email' ? [_c('v-col', {
      attrs: {
        "cols": "8",
        "md": field.additional_field ? '4' : '8'
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": field.name,
        "rules": field.rules
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var errors = _ref.errors;
          return [_c('v-text-field', {
            attrs: {
              "outlined": "",
              "dense": "",
              "flat": "",
              "type": field.type,
              "error-messages": errors,
              "autocomplete": "chrome-off"
            },
            model: {
              value: field.value,
              callback: function callback($$v) {
                _vm.$set(field, "value", $$v);
              },
              expression: "field.value"
            }
          })];
        }
      }], null, true)
    })], 1), field.additional_field ? _c('v-col', {
      attrs: {
        "cols": "8",
        "md": field.additional_field ? '4' : '8',
        "offset": "4",
        "offset-md": "0"
      }
    }, [_c('validation-provider', {
      attrs: {
        "name": field.additional_field.name,
        "rules": field.additional_field.rules
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref2) {
          var errors = _ref2.errors;
          return [_c('v-text-field', {
            attrs: {
              "outlined": "",
              "dense": "",
              "flat": "",
              "error-messages": errors
            },
            model: {
              value: field.additional_field.value,
              callback: function callback($$v) {
                _vm.$set(field.additional_field, "value", $$v);
              },
              expression: "field.additional_field.value"
            }
          })];
        }
      }], null, true)
    })], 1) : _vm._e()] : field.type === 'link' ? [_c('v-col', {
      attrs: {
        "cols": "8"
      }
    }, [_c('v-btn', {
      staticClass: "text-capitalize",
      attrs: {
        "color": "primary",
        "text": "",
        "small": ""
      },
      on: {
        "click": _vm.sendResetEmail
      }
    }, [_vm._v(" " + _vm._s(field.value) + " "), _c('v-icon', {
      attrs: {
        "size": "12",
        "right": ""
      }
    }, [_vm._v("$edit")])], 1)], 1)] : _vm._e()], 2)], 1);
  })], 2)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-avatar', {
    staticClass: "white--text",
    attrs: {
      "color": "grey",
      "size": "80"
    }
  }, [_c('v-img', {
    attrs: {
      "src": !_vm.preview ? _vm.file : _vm.preview,
      "width": "40"
    }
  })], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "avatar",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-file-input', {
          ref: "file",
          staticClass: "d-none",
          attrs: {
            "accept": "image/*",
            "error-messages": errors
          },
          on: {
            "change": _vm.updateFileView
          }
        })];
      }
    }])
  }), _c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "text": "",
      "small": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.file.$refs.input.click();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" " + _vm._s(_vm.$t("upload_file")) + " ")], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular px-2",
    attrs: {
      "color": "error",
      "text": "",
      "disabled": _vm.formStatus,
      "loading": _vm.formStatus,
      "depressed": "",
      "small": "",
      "min-width": "120"
    },
    on: {
      "click": function click($event) {
        return _vm.deleteUser();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("delete")) + " ")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "text-capitalize font-weight-regular",
    attrs: {
      "type": "submit",
      "color": "primary",
      "disabled": _vm.formStatus,
      "loading": _vm.formStatus,
      "depressed": "",
      "small": "",
      "min-width": "120"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")])], 1)], 1)], 1)], 1)], 1)], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }